export const loginHref = ({
  router: {
    query: {
      redirect,
    },
    query,
    ...rest
  },
}) => {
  if (!redirect) return '/login'

  return `/login?redirect=${encodeURIComponent(redirect)}`
}
