import React from 'react'
import { FocusLayout } from '~components/layouts/FocusLayout'
import { useEnsureCurrentAgent } from '~hooks/auth/useEnsureCurrentAgent'

export const AuthenticatedFocusLayout = (props) => {
  useEnsureCurrentAgent()

  return (
    <FocusLayout
      {...props}
    />
  )
}
