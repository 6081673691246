import { Box } from '~components/Box'

export const TopbarBase = ({
  gridTemplateColumns = '1fr 2fr 1fr',
  ...rest
}) => (
  <Box
    display='grid'
    gridTemplateColumns={gridTemplateColumns}
    alignItems='center'
    borderBottom='separator'
    marginBottom={4}
    marginX={3}
    paddingY={3}
    {...rest}
  />
)
