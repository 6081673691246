import { BaseLayout } from '~components/layouts/BaseLayout'
import { Box } from '~components/Box'
import { Topbar } from './Topbar'

export const FocusLayout = ({
  logoUrl = '/',
  ...rest
}) => (
  <BaseLayout>
    <Box
      backgroundColor='light-primary'
      minHeight='100vh'
      paddingBottom={3}
    >
      <Topbar logoUrl={logoUrl} />
      <Box
        flexGrow={1}
        {...rest}
      />
    </Box>
  </BaseLayout>
)
