import React from 'react';
import { useRouter } from 'next/router'
import Head from 'next/head'
import _ from 'lodash'
import { url } from './lib/url';
import { ogImageUrl } from './lib/ogImageUrl';

const defaultSettings = {
  title: 'Atlasmic',
  ogImage: '/index.png',
  description: 'Atlasmic is a Sales Acceleration Platform that helps you boost your sales by talking with your website visitors.',
  twitterSite: '@AtlasmicHQ',
  twitterCreator: '@AtlasmicHQ',
  lang: 'en',
}

export const Meta = (props) => {
  const router = useRouter()

  const {
    title,
    description,
    ogImage,
    twitterSite,
    twitterCreator,
    lang,
    publishedTime,
    modifiedTime,
    authorFirstName,
    authorLastName,
    authorUsername,
    authorGender,
  } = _.defaults(_.cloneDeep(props), defaultSettings, {})

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="manifest" href="/manifest.json" />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      <meta property="og:image" content={ogImageUrl(ogImage)} />
      <meta property="og:image:url" content={ogImageUrl(ogImage)} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:url" content={url({ router })} />
      <link rel="canonical" href={url({ router })} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content={title} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content={twitterSite} />
      <meta property="twitter:creator" content={twitterCreator} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={ogImageUrl(ogImage)} />

      {publishedTime && <meta property="article:published_time" content={publishedTime} />}
      {modifiedTime && <meta property="article:modified_time" content={modifiedTime} />}
      {authorFirstName && <meta property="article:author:first_name" content={authorFirstName} />}
      {authorLastName && <meta property="article:author:last_name" content={authorLastName} />}
      {authorUsername && <meta property="article:author:username" content={authorUsername} />}
      {authorGender && <meta property="article:author:gender" content={authorGender} />}

      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#1769FF" />
      <meta name="apple-mobile-web-app-title" content="Atlasmic" />
      <meta name="application-name" content="Atlasmic" />
      <meta name="msapplication-TileColor" content="#1769FF" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="msapplication-config" content="/browserconfig.xml" />
    </Head>
  )
}
