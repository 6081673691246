import { Box } from '~components/Box'

export const FocusInformationBase = ({
  icon,
  title,
  description,
  children,
  width = 20,
  height = 20,
}) => (
  <Box display='flex' alignItems='center' justifyContent='center' paddingX={2}>
    <Box flexGrow={0} paddingY={8}>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='flex-end'
          width={20}
          height={20}
        >
          <Box component={icon} height={height} width={width} />
        </Box>
        <Box paddingTop={3}>
          <Box textStyle='h3' color='dark' textAlign='center'>
            {title}
          </Box>
          <Box paddingTop={3} display='flex' justifyContent='center'>
            <Box
              textStyle='bigger-text'
              color='dark-gray'
              textAlign='center'
              maxWidth={80}
            >
              {description}
            </Box>
          </Box>
        </Box>

        {children}
      </Box>
    </Box>
  </Box>
)
