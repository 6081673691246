import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { useCurrentAgent } from '@atlasmic/shared/hooks/agents/useCurrentAgent'
import { onError } from '~utils/errors/onError'

export const useEnsureCurrentAgent = () => {
  const { addToast } = useToast()

  const { currentAgent, loading, error } = useCurrentAgent({
    onError: onError({ addToast }),
  })

  return null
}
