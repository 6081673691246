import { loader } from '@atlasmic/icons'
import { FocusInformationBase } from '~components/FocusInformationBase'
import { AuthenticatedFocusLayout } from '~components/layouts/AuthenticatedFocusLayout'
import { Meta } from '~components/Meta'

export const AuthenticatedFocusLoading = ({
  metaTitle = 'Atlasmic',
  ...rest
}) => (
  <AuthenticatedFocusLayout>
    <Meta title={metaTitle} />

    <FocusInformationBase icon={loader} width={12} height={12} {...rest} />
  </AuthenticatedFocusLayout>
)
