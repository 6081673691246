import Link from 'next/link'
import { useRouter } from 'next/router'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { onError } from '~utils/errors/onError'
import { useCurrentAgent } from '@atlasmic/shared/hooks/agents/useCurrentAgent'
import { Skeleton } from '@atlasmic/shared/components/skeletons/misc/Skeleton'
import { Box } from '~components/Box'
import { loginHref } from './lib/loginHref'

export const CurrentAgentStatus = ({ useCurrentAgentProps }) => {
  const router = useRouter()
  const { addToast } = useToast()
  const { currentAgent, loading, error } = useCurrentAgent({
    onError: onError({ addToast }),
    ...useCurrentAgentProps,
  })

  if ((loading || error) && !currentAgent) {
    return (
      <Box display='flex' justifyContent='flex-end'>
        <Skeleton textStyle='text' width={20} flexGrow={0} />
      </Box>
    )
  }

  if (currentAgent) {
    return (
      <Box display='flex' flexDirection='column' alignItems='flex-end'>
        <Box display={{ xs: 'none', md: 'block' }} textAlign='right'>
          <Box display='inline' textStyle='text' color='dark-gray'>
            Signed in as
          </Box>{' '}
          <Box
            textStyle='text'
            display='inline'
            color='dark-gray'
            fontWeight={700}
            whiteSpace='nowrap'
          >
            {currentAgent.email}
          </Box>
        </Box>
        <Link href='/logout'>
          <a>
            <Box
              textStyle='text'
              textAlign='right'
              display='inline'
              color='dark-gray'
              hoverTextDecoration='underline'
            >
              Logout
            </Box>
          </a>
        </Link>
      </Box>
    )
  }

  return (
    <Box textStyle='text' textAlign='right'>
      <Box display={{ xs: 'none', md: 'inline' }} color='dark-gray'>
        Have an account already?{' '}
      </Box>
      <Link href={loginHref({ router })}>
        <a>
          <Box display='inline' color='primary' hoverTextDecoration='underline'>
            Sign in
          </Box>
        </a>
      </Link>
    </Box>
  )
}
