import _ from 'lodash'
import Link from 'next/link'
import { Box } from '~components/Box'
import {
  atlasmicRectangleWithText,
} from '@atlasmic/icons/logos'
import { TopbarBase } from '~components/topbars/TopbarBase'
import { CurrentAgentStatus } from '~components/CurrentAgentStatus'

export const Topbar = ({
  logoUrl,
}) => (
  <TopbarBase
    gridTemplateColumns='repeat(2, 1fr)'
  >
    <Box>
      <Link href={logoUrl}>
        <a>
          <Box
            component={atlasmicRectangleWithText}
            fill='primary'
          />
        </a>
      </Link>
    </Box>
    <CurrentAgentStatus
      useCurrentAgentProps={{
        fetchPolicy: 'cache-only',
      }}
    />
  </TopbarBase>
)
